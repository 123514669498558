<template>
  <div class="serve-list-wrap">
    <div class="opt-serve-list">
      <div class="title">
        <div>项目</div>
        <div class="title-item">理疗师</div>
        <div class="title-item">操作</div>
      </div>
      <template v-for="(item, index) in ruleForm.optProjectList">
        <div class="serve-item">
          <div @click="handleToogleClick(item, index)" class="project">
            <div>
              <div class="s-name">{{ item.serveName }}</div>
              <div class="serveTimeLength">服务:约{{ item.serveLength }}分钟</div>
              <!-- <div>筹备:15分钟</div> -->
            </div>
            <div class="icon">
              <el-icon size="20px">
                <ArrowDown />
              </el-icon>
            </div>
          </div>
          <div class="teacher">{{ currentOptTeacherName }}</div>
          <div @click="handleRemoveClick(item, index)" class="handle">删除</div>
        </div>
      </template>
    </div>
    <el-popover
      ref="popoverRef"
      v-model:visible="popoverVisible"
      placement="top-start"
      width="630px"
    >
      <div class="reserve-project-list">
        <div class="reserve-top-form">
          <!-- <el-select
            class="reserve-el-select"
            size="small"
            @change="handleServeTypeChange"
            v-model="ruleForm.optServeId"
            placeholder="选择服务分类"
          >
            <el-option
              v-for="item in teacherServeCategoryListNew"
              :key="item.kindId"
              :label="item.kindName"
              :value="item.kindId"
            />
          </el-select> -->
          <el-input
            class="reserve-el-select"
            @input="handleSearch"
            size="small"
            prefix-icon="search"
            v-model="searchValue"
            placeholder="输入名称"
          />
        </div>
        <div class="serve_flex">
          <div class="category-list">
            <template :key="item" v-for="(item, index) in teacherServeList">
              <div @click="handleTypeClick(item, index)" :class="{ active: ruleForm.optServeId == item.kindId }" class="item">
                {{ item.kindName }}
              </div>
            </template>
          </div>
          <el-scrollbar height="270px" width="520px">
          <div class="reserve-teacher-project">
            <template v-if="teacherServeInfoList.length > 0">
              <template v-for="item in teacherServeInfoList">
                <div @click="handleItemClick(item)" class="reserve-item">
                  <div class="reserve-left">
                    {{ item.serveName }}
                  </div>
                  <div class="reserve-right">
                    <div class="reserve-price">¥{{ item.price }}</div>
                    <div>约{{ item.serveLength }}分钟</div>
                  </div>
                </div>
              </template>
            </template>
            <template v-else>
              <div class="empty">
                <el-empty description="暂无服务" />
              </div>
            </template>
          </div>
        </el-scrollbar>
        </div>
      </div>
      <template #reference>
        <el-button size="small" plain @click="popoverVisible = true">添加</el-button>
      </template>
    </el-popover>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, watch, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import emitter from '@/utils/eventbus'
import { getTeacherRankServeList } from "@/service/main/reserve";

export default defineComponent({
  props: {
    dataList: {
      type: Array,
      default() {
        return []
      }
    },
    currentOptTeacherName: {
      type: String,
      default: '123'
    },
    /*
    category
    0普通添加
    1 待分配
    */
    category: {
      type: Number,
      default: 0
    },
    teacherServeCategoryList: {
      type: Array,
      default() {
        return []
      }
    },
    popoverWidth: {
      type: Number,
      default: 300
    },
    empId: {
      type: Number,
      default: 0
    },
    shopId: {
      type: Number,
      default: 0
    }
  },
  components: {

  },
  emits: ['optProjectChange', 'handleServeTypeChange'],
  setup(props, { emit }) {
    const store = useStore()
    const teacherServeList = ref([])
    const teacherServeInfoList = ref([])
    const popoverRef = ref()
    const searchValue = ref('')
    emitter.on('emptyReserveOptProjectList', () => {
      ruleForm.optProjectList = []
    })
    watch(() => props.empId, async (n) => {
      if (n) {
        getServeCatewgory()
      }
    }, {
      deep: true
    })

    const ruleForm = reactive({
      optServeId: "",
      optProjectList: [],
      isToogle: false,
      toogleIndex: -1
    })

    const handleServeTypeChange = value => {
      popoverRef.value.show()
      let list = teacherServeList.value
      // console.log(list);
      // console.log(String(value.length > 0));

      if (String(value).length > 0) {
        list = list.filter(ele => ele.serveKind == value)
        // console.log(123123);
      }
      teacherServeInfoList.value = list
      // console.log(teacherServeInfoList.value);

    }
    const handleTypeClick = (item, index) => {
      let value = item.kindId;
      ruleForm.optServeId = value;
      popoverRef.value.show()
      teacherServeInfoList.value = item.infos
    }

    const handleItemClick = (item) => {
      // 判断是更换项目还是添加项目
      let flag = ruleForm.isToogle
      if (!flag) {
        ruleForm.optProjectList.push(item)
      } else {
        let index = ruleForm.toogleIndex
        ruleForm.optProjectList[index] = item
      }
      ruleForm.toogleIndex = -1
      ruleForm.isToogle = false
      popoverVisible.value = false
      handleCheckChange()
    }

    const popoverVisible = ref(false)
    const handleRemoveClick = (item, index) => {
      ruleForm.optProjectList.splice(index, 1)
      handleCheckChange()
    }

    const handleCheckChange = () => {
      let list = ruleForm.optProjectList
      let reserveArr = []
      let totalTime = 0
      let prepareTime = 0
      list.forEach((item, index) => {
        reserveArr.push(`${item.serveName},${item.serveId},${item.price},${item.serveLength}`)
        totalTime += item.serveLength
        prepareTime += item.prepare
      })
      emit('optProjectChange', {
        reserveArr,
        totalTime,
        prepareTime
      })
      // console.log(reserveArr);
      // console.log(totalTime);
    }

    const handleToogleClick = (item, index) => {
      ruleForm.toogleIndex = index
      ruleForm.isToogle = true
      popoverRef.value.show()
    }

    const handleSearch = value => {
      let list = teacherServeList.value.filter(item=>{ return item.kindId==ruleForm.optServeId })[0].infos
      let newList = []
      // console.log(list)
      // console.log(value.trim().length > 0);
      if (value.trim().length > 0) {
        list.forEach(ele => {
          // console.log(ele.serveName.indexOf(value) != -1);
          if (ele.serveName.indexOf(value) != -1) {

            newList.push(ele)
          }
        })
      } else {
        newList = list
      }

      teacherServeInfoList.value = newList

    }
    const getServeCatewgory = async () => {
        const res = await getTeacherRankServeList({userId:props.empId,shopId:props.shopId})
        teacherServeList.value=res.data
        teacherServeInfoList.value=teacherServeList.value[0].infos
        ruleForm.optServeId = teacherServeList.value[0].kindId;
    }

    onUnmounted(() => {
      emitter.off('emptyReserveOptProjectList')
    })
    return {
      handleSearch,
      searchValue,
      handleCheckChange,
      popoverRef,
      handleToogleClick,
      handleRemoveClick,
      popoverVisible,
      handleServeTypeChange,
      teacherServeList,
      ruleForm,
      handleItemClick,
      handleTypeClick,
      teacherServeInfoList
    }

  }
})
</script>

<style scoped lang="less">
.serve-list-wrap {
  margin-top: 20px;

  .opt-serve-list {
    .title {
      background-color: rgb(239, 239, 239);
      display: flex;
      border-bottom: 1px solid rgb(239, 239, 239);
      margin-bottom: 10px;
      & > div:nth-child(1) {
        flex: 1.4 !important;
        padding: 0 10px;
      }
      & > div:nth-child(3) {
        padding-right: 10px;
      }
      .title-item {
        flex: 1;
        text-align: right;
      }
    }
    .serve-item:hover {
      background-color: rgb(246, 244, 255);
    }

    .serve-item {
      display: flex;
      font-size: 13px;
      padding: 10px 0;
      border-bottom: 1px solid rgb(239, 239, 239);
      cursor: pointer;

      .teacher,
      .handle {
        flex: 1;
      }

      .teacher {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      .project {
        flex: 1.4;
        border: 1px solid rgb(235, 237, 240);
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        align-items: center;

        .icon {
          display: flex;

          i {
            font-size: 16px;
          }
        }

        .s-name {
          font-size: 14px;
          font-weight: 600;
        }

        & > div {
          line-height: 24px;
        }
      }

      .handle {
        color: rgb(133, 88, 250);
        justify-content: flex-end;
        padding-right: 10px;
      }

      .teacher-name,
      .handle {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
      }
    }
  }
}
</style>

<style lang="less">
.el-popper {
  padding: 0 !important;
}

.reserve-project-list {
  .reserve-teacher-project {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
    width: 520px;

    .reserve-item:hover {
      border-color: rgb(133, 88, 250);
    }

    .reserve-item {
      border: 1px solid white;
      width: 48%;
      background-color: rgb(246, 244, 255);
      margin-bottom: 10px;
      border-radius: 6px;
      display: flex;
      justify-content: space-between;
      padding: 10px;
      cursor: pointer;
      font-size: 12px;

      .reserve-right {
        line-height: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .reserve-price {
          color: rgb(133, 88, 250);
        }
      }

      .reserve-reserve-left {
      }
    }
  }
  .serve_flex{
    display: flex;
  }
  .active {
    background-color: #fff !important;
    color: rgb(133, 88, 250);
    font-weight: 550;
  }
  .category-list {
    width: 100px;

    .item {
      cursor: pointer;
      text-align: center;
      font-size: 14px;
      line-height: 45px;
      background-color: rgb(239, 239, 239);
      border-bottom: 1px solid rgb(255, 255, 255);
      min-width: 100px;
      cursor: pointer;
    }
  }

  .reserve-top-form {
    display: flex;
    justify-content: space-between;
    background-color: rgb(239, 239, 239);
    padding: 10px 20px;

    .reserve-el-select {
      width: 45%;
    }
  }

  &:deep(.serve-list-wrap) {
    padding-right: 10 !important;
  }

  .empty {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>

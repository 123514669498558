const roomBreadcrumbList = [
  {
    name: '预约管理',
    path: ''
  },
  {
    name: '预约看板',
    path: ''
  }
  // ,
  // {
  //   name: '员工看板维度',
  //   path: ''
  // }
]

const physiotherapyBreadcrumbList = [
  {
    name: '预约管理',
    path: ''
  },
  {
    name: '预约看板',
    path: ''
  },
  {
    name: '理疗维度',
    path: ''
  }
]

const rules = {
  userMobile: [
    { required: true, message: '请输入手机号', trigger: 'blur' },
    { pattern: /^1[3456789]\d{9}$/, message: '输入11位手机号', trigger: 'blur' }
  ],
  userRealname: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
  shopId: [{ required: true, message: '请选择门店', trigger: 'blur' }],
  empId: [{ required: true, message: '请选择理疗师', trigger: 'blur' }],
  arriveTime: [{ required: true, message: '请选择到店时间', trigger: 'blur' }],
  arriveDate: [{ required: true, message: '请选择到店日期', trigger: 'blur' }]
}

const occupyMinute = [
  {
    label: '0分钟',
    value: 0
  },
  {
    label: '15分钟',
    value: 15
  },
  {
    label: '30分钟',
    value: 30
  },
  {
    label: '45分钟',
    value: 45
  }
]

const addOccupyTimeRules = {
  date: [{ required: true, message: '请选择日期', trigger: 'blur' }],
  startTime: [{ required: true, message: '请选择起始时间', trigger: 'blur' }],
  // hour: [{ required: true, message: '请选择小时', trigger: 'blur' }],
  cause: [{ required: true, message: '请输入原因', trigger: 'blur' }],
  endTime: [{ required: true, message: '请选择结束时间', trigger: 'blur' }]
}

export {
  roomBreadcrumbList,
  physiotherapyBreadcrumbList,
  rules,
  occupyMinute,
  addOccupyTimeRules
}

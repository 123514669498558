<template>
  <div class="add-reserve-dialog">
    <page-dialog :isShowFooter="false" @handleDialogClick="handleDialogClick" :width="46" @changeDialogVisible="changeDialogVisible" :dialogVisible="dialogVisible" title="添加预约">
      <el-scrollbar @click="handlePageDialogClick" height="600px">
        <el-form :rules="rules" ref="ruleFormRef" label-position="left" :model="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item v-if="category == 0" label="手机号：" prop="userMobile">
            <div class="search-user">
              <div class="phone">
                <el-input ref="phoneInputRef" @keyup="handlePhoneEnter($event, 0)" @mouseup="handlePhoneEnter($event, 0)" @change="handlePhonechange" v-model.trim="ruleForm.userMobile" placeholder="" />
                <div v-if="currentUser.hasOwnProperty('userAva') && isShowUserInfo" class="user-info">
                  <img :src="currentUser.userAva" alt="" />
                  <div class="name">{{ currentUser.userRealname }}</div>
                  <div class="phone">{{ currentUser.userMobile }}</div>
                  <el-icon @click="handleClosrClick" class="close">
                    <close />
                  </el-icon>
                </div>
              </div>
              <div v-if="isShowSearchTips" class="search-tips">
                <template :key="useritem" v-for="useritem in searchResList">
                  <div @click="handleSearchUserClick(useritem)" class="user-item">
                    <img :src="useritem.userAva" alt="" />
                    <div class="name">
                      {{ useritem.userRealname }}({{ useritem.userMobile }})
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </el-form-item>

          <el-form-item label="姓名" v-if="category == 0" prop="userRealname">
            <div class="search-user">
              <div class="phone">
                <el-input @keyup="handlePhoneEnter($event, 1)" @mouseup="handlePhoneEnter($event, 1)" v-model.trim="ruleForm.userRealname" placeholder="" />
                <div v-if="isShowSearchTips2" class="search-tips search-tips2">
                  <template :key="useritem" v-for="useritem in searchResList">
                    <div @click="handleSearchUserClick(useritem)" class="user-item">
                      <img :src="useritem.userAva" alt="" />
                      <div class="name">
                        {{ useritem.userRealname }}({{ useritem.userMobile }})
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </el-form-item>

          <el-form-item v-if="category == 0" label="选择门店:" prop="shopId">
            <div class="shop">
              <el-select @change="handleShopChange" v-model="ruleForm.shopId" class="m-2" placeholder="选择门店" size="large">
                <el-option v-for="item in shopList" :key="item.shopId" :label="item.shopName" :value="item.shopId">
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item prop="empId" label="选择理疗师:">
            <div ref="teacherRef" class="teacher">
              <el-form-item prop="empId">
                <el-select @change="handleTeacherChange" class="teacher" v-model="ruleForm.empId" placeholder="选择理疗师" size="large">
                  <el-option @click="handleOptTeacherClick(item)" v-for="item in shopTeacherList" :key="item.userId" :label="item.userRealname" :value="item.userId" />
                </el-select>
              </el-form-item>
            </div>
            <div class="serve-content">
              <div class="serve-list">
                <serveList :currentOptTeacherName="currentOptTeacherName" @optProjectChange="handleOptProjectChange" :popoverWidth="popoverWidth" @handleServeTypeChange="handleServeTypeChange" :teacherServeCategoryList="teacherServeCategoryList" :shopId="ruleForm.shopId" :empId="ruleForm.empId" >
                </serveList>
              </div>
            </div>
          </el-form-item>
          <div style="display:flex;">
            <el-form-item v-if="category == 0" label="服务时长：" prop="totalTime">
              <div class="search-user">
                <div class="phones">
                  <el-select class="teacher" v-model="ruleForm.totalTime" placeholder="选择服务时长" size="large">
                    <el-option v-for="(item,index) in totalTimeList" :key="index" :label="item" :value="item" />
                  </el-select>
                </div>
              </div>
            </el-form-item>
            <el-form-item class="form-input" label="筹备时长：" prop="prepare" style="margin-left: 10px;" label-width="80px">
              <div class="search-user">
                <div class="phones">
                  <el-select class="teacher" v-model="ruleForm.prepare" placeholder="选择筹备时长" size="large">
                    <el-option v-for="(item,index) in totalTimeList2" :key="index" :label="item" :value="item" />
                  </el-select>
                  <span style="margin-left:10px;">分钟</span>
                </div>
              </div>
            </el-form-item>
          </div>
          <el-form-item prop="arriveDate" label="到店日期:">
            <div class="arrive-date">
              <el-form-item prop="arriveDate">
                <el-date-picker @change="handleDateChnage" :clearable="false" :disabled-date="disabledDate" class="arriveDate" v-model="ruleForm.arriveDate" type="date" placeholder="请选择日期" />
              </el-form-item>
              <el-form-item prop="arriveTime">
                <!-- <el-select
                  readonly
                  clearable
                  v-model="ruleForm.arriveTime"
                  placeholder="选择时间"
                >
                  <el-option
                    v-for="item in teacherTimeList"
                    :key="item.id"
                    :label="item.appTime"
                    :value="item.appTime"
                    :disabled="item.timeStatus != 0"
                  />
                </el-select> -->

                <!-- v-model:visible="popoverVisible" -->
                <el-popover ref="popoverRef" v-model:visible="popoverVisible" placement="bottom" :width="470">
                  <div class="reserve-project-list">
                    <el-scrollbar height="260px">
                      <div class="reserve-time-project">
                        <template v-for="(item, index) in teacherTimeList" :key="item.id">
                          <template v-if="item.isDisplay&&item.timeStatus == 1">
                            <div :class="{ optTimeActive: currentOptIndex == index }" @click="handleTimeClick(item.time, index)" class="reserve-item-time">
                              {{ item.time }}
                              <div style="font-size: 10px;">可预约</div>
                            </div>
                          </template>
                          <template v-else>
                            <div class="reserve-item-time reserve-item-time-disabled">
                              {{ item.time }}
                              <div style="font-size: 10px;">已约满</div>
                            </div>
                          </template>
                        </template>
                      </div>
                    </el-scrollbar>
                  </div>
                  <template #reference>
                    <div @click="popoverVisible = true" class="arriveTime">
                      <div class="opt-time">
                        {{ ruleForm.arriveTime == "" ? "选择时间" : ruleForm.arriveTime }}
                      </div>
                      <span></span>
                    </div>
                  </template>
                </el-popover>
                <!-- <el-time-select v-model="ruleForm.arriveTime" :start="timeStart" step="00:10" end="23:40"
                  placeholder="选择时间" /> -->
              </el-form-item>
            </div>
          </el-form-item>
          <el-form-item label="备注:" prop="remark">
            <div class="remark">
              <el-input v-model="ruleForm.remark" :rows="2" type="textarea" placeholder="请输入备注" />
            </div>
          </el-form-item>
        </el-form>
      </el-scrollbar>
      <div class="save-btn">
        <el-button @click="handleCancleClick" size="small" plain>取消</el-button>
        <el-button @click="handleSaveClick(ruleFormRef)" size="small" type="primary" :loading="loading">预约</el-button>
      </div>
    </page-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, watch, onUnmounted, onMounted, nextTick } from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { tips } from '@/utils/tips'
import { COMPANY_ID, RESERVE_START_TIME } from '@/common/constant'
import { useState } from '@/hooks'
import emitter from '@/utils/eventbus'
import cache from '@/utils/cache'
import ruleFormVue from '@/views/main/reserve/site/base-ui/rule-form/rule-form.vue'
import { searchKeywordCustomer, getShopServeList, getServeCategoryList, newApp,getUserTimeList } from '@/service/main/reserve'
import dayjs from 'dayjs'
import { rules } from '../../../config'
import { ElMessage } from 'element-plus'
import {
  debounce
} from 'lodash'
import serveItem from './cpns/serve-item/serve-item.vue'
import serveList from './cpns/serve-list/serve-list.vue'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    addInfo: {
      type: Object,
      default() {
        return {}
      }
    },

    currentStaffIndex: {
      type: Number,
      default: 0
    },
    /*
    category
    0 默认添加预约
    1 预约详情抽屉
    */
    category: {
      type: Number,
      default: 0
    }
  },
  components: {
    PageDialog,
    serveItem,
    serveList
  },
  emits: ['changeDialogVisible', 'updateReserveProject'],
  setup(props, { emit }) {
    const store = useStore()
    const loading = ref(false)

    const changeDialogVisible = (flag) => {
      if (flag) {
        let optarrive = props.addInfo.startTime
        let date1 = dayjs(ruleForm.arriveDate).format('YYYY-MM-DD')
        let date2 = dayjs(new Date()).format('YYYY-MM-DD')
        let optdate = dayjs(date1).valueOf()
        let curdate = dayjs(date2).valueOf()

        // 如果是当天
        if (optdate == curdate) {
          let date3 = dayjs(date1 + optarrive).valueOf()
          let date4 = dayjs(new Date()).valueOf()
          if (date3 > date4) {
            ruleForm.arriveTime = optarrive
          }
        } else {
          ruleForm.arriveTime = optarrive
        }

        getTeacherTime()
      }

      emit('changeDialogVisible', flag)
      handleClosrClick()
    }

    const handleCancleClick = () => {
      changeDialogVisible(false)
    }

    const teacherRef = ref()
    const popoverWidth = ref()
    const totalTimeList = reactive([
      '10','20','30','40','50','60','70','80','90','100','110','120','130','140','150','160','170','180'
    ])
    const totalTimeList2 = reactive([
      '0','10','20','30','40'])

    watch(teacherRef, (n) => {
      if (n != null) {
        let width = n.offsetWidth
        popoverWidth.value = width - 20
      }
    }, {
      deep: true,
      immediate: true
    })

    const ruleForm = reactive({
      companyId: COMPANY_ID,
      userId: "",
      shopId: "",
      arriveDate: new Date(),
      arriveTime: "",
      empId: "",
      remark:'',
      userMobile: "",
      appMemo: "",
      arriveKind: 1,
      arrivePerson: '',
      reserveLastTime: "24:00",
      serveStr: '',
      totalTime: "",
      prepare:"",
      reserveArr: []
    })

    const optServeId = ref('')

    const handleClosrClick = () => {
      ruleForm.userId = ''
      ruleForm.arrivePerson = ''
      isShowUserInfo.value = false
      ruleForm.userMobile = ''
      ruleForm.userRealname = ''
      ruleForm.shopId = ''
      ruleForm.empId = ''
    }

    const emptyForm = () => {
      for (let key in ruleForm) {
        if (key == 'shopId' || key == 'companyId' || key == 'prepay' || key == 'arriveDate' || key == 'prepayAmount' || key == 'promPrice' || key == 'appStatus' || key == 'isPoint' || key == 'arriveKind') {
          continue;
        } else {
          ruleForm[key] = ''
        }
      }
    }

    emitter.on('changeAddReserveDialogTime', time => {
      ruleForm.arriveDate = time
    })

    emitter.on('changeAddReserveDialogShopId',async shopId => {
      ruleForm.shopId = shopId
      if(shopId!=0){
        await store.dispatch('reserve/getShopTeacherListAction', shopId)
      }
    })

    const timeStart = computed(() => {
      let optDate = dayjs(ruleForm.arriveDate).valueOf()
      let curDate = dayjs().valueOf()
      let mm = dayjs().format('mm')
      let HH = dayjs().format('HH')
      if (optDate - curDate > 0) {
        // console.log(1);
        HH = '07'
        mm = '00'
      } else {
        // console.log(2);
        if (mm > 0 && mm <= 10) {
          mm = '10'
        }
        else if (mm > 10 && mm <= 20) {
          mm = '20'
        }

        else if (mm > 20 && mm <= 30) {
          mm = '30'
        }

        else if (mm > 30 && mm <= 40) {
          mm = '40'

        }
        else if (mm > 40 && mm <= 50) {
          mm = '50'

        }
        else if (mm > 50 && mm <= 59) {
          mm = '00'
          HH = Number(HH) + 1
        }
      }


      let time = String(HH) + ':' + String(mm)
      // console.log(time);
      return time

    })

    const handleDateChnage = v => {
      // console.log(v);
      getTeacherTime()
    }

    const teacherTimeList = ref([])


    const getTeacherTime = async () => {
      try {
        let { arriveDate, empId,totalTime,prepare } = ruleForm
        if (empId == '') return
        arriveDate = dayjs(arriveDate).format('YYYY-MM-DD')
        const res = await getUserTimeList({empId, date:arriveDate,serveTimeLength:totalTime,prepare,shopId:ruleForm.shopId})
        if(res.data&&res.data.length){
            res.data.map(item=>{
              item.time=item.time.split(':')[0]+':'+item.time.split(':')[1]
            })
            teacherTimeList.value = res.data
            let optTimeIndex = res.data.findIndex(ele => ele.time == ruleForm.arriveTime)
            currentOptIndex.value = optTimeIndex
        }
        
      } catch (error) {
        // console.log(error);
      }
    }

    watch(() => ruleForm.empId, () => {
      getTeacherTime()
    })
    watch(() => ruleForm.totalTime, () => {
      getTeacherTime()
    })
    watch(() => ruleForm.prepare, () => {
      getTeacherTime()
    })


    const shopList = computed(() => store.state.reserve.shopList)
    const shopTeacherList = computed(() => store.state.reserve.shopTeacherList)
    const teacherServeList = computed(() => store.state.reserve.teacherServeList)
    const clientInfo = computed(() => store.state.reserve.clientInfo)
    const teacherServeInfoList = ref([])
    watch(teacherServeList, (n) => {
      if (n != null) {
        teacherServeInfoList.value = n
      }
    }, {
      deep: true
    })

    const handleServeTypeChange = (value) => {
      let serveList = teacherServeList.value
      if (value.length == 0) {
        teacherServeInfoList.value = serveList
      } else {
        let arr = serveList.filter(ele => ele.serveKind == value)
        teacherServeInfoList.value = arr
      }
    }

    const handleTeacherClick = item => {
      currentTeacherLvid.value = item.lvid
    }

    const currentTeacherLvid = ref(-1)
    const getTeacherServeList = (tid) => {
      store.dispatch('reserve/getTeacherServeListAction',{userId:tid,shopId:ruleForm.shopId})
    }

    const shopServeList = ref()
    const getShopServeListFunc = async () => {
      let res = await getShopServeList(ruleForm.shopId)
      let list = res.data.list
      let serveList = []
      list.forEach(ele => {
        serveList.push({
          serveId: ele.serveId,
          serveName: ele.serveName,
          paralist: [
            {
              salePrice: ele.salePrice,
              serveTimeLength: ele.serveTimeLength,
              paraId: ele.paraId
            }
          ]
        })
      })
      shopServeList.value = serveList
    }

    watch(() => props.addInfo, async (n) => {
      if (n != null) {
        store.commit('reserve/changeClientInfo', {})
        checkList.value = null
        emptyForm()
        ruleForm.empId = n.teacherId
        getTeacherServeList(n.teacherId)

        ruleForm.reserveLastTime = n.lastTime
        currentOptTeacherName.value = n.teacherName
        // if (props.addInfo.teacherId) {
        //   const serveTypeRes = await getServeCategoryList(COMPANY_ID)
        //   let list = [...teacherServeCategoryList.value, ...serveTypeRes.data.list]
        //   teacherServeCategoryList.value = list
        // }
      }
    }, {
      deep: true
    })

    const handleShopChange = async (value) => {
      ruleForm.empId = ''
      // debugger
      if(value!=0) {
        await store.dispatch('reserve/getShopTeacherListAction', value)
      }
      getShopServeListFunc()
    }



    const handleTeacherChange = (value) => {
      nextTick(() => {
        getTeacherServeList(value)
        emitter.emit('emptyReserveOptProjectList')
      })
    }

    const currentOptTeacherName = ref()
    const handleOptTeacherClick = item => {
      currentOptTeacherName.value = item.userRealname
      currentTeacherLvid.value = item.lvid
    }

    const checkList = ref()
    const currentOptServe = reactive({
      serveName: '',
      serveId: "",
      flag: false,
      paraId: ""
    })

    const handleServeClick = (item) => {
      // 选择项目逻辑
      currentOptServe.serveName = item.serveName
      currentOptServe.serveId = item.serveId
      ruleForm.serveName = item.serveName
      ruleForm.serveId = item.serveId
    }

    const handleCheckBoxChange = (value) => {
      // 选择项目逻辑
      currentOptServe.flag = value
    }

    const handleCheckbox = ele => {
      // 选择项目逻辑
      nextTick(() => {
        if (currentOptServe.flag) {
          currentOptServe.paraId = ele.paraId
          ruleForm.paraId = ele.paraId
          ruleForm.servePrice = ele.salePrice
          ruleForm.serveTimeLength = ele.serveTimeLength
        }
      })
    }

    const handlePhonechange = value => {
      ruleForm.userId = ''
      if (value.length >= 11) {
        store.dispatch('reserve/getClientInfoAction', value)
      }
    }


    const searchResList = ref([])
    const isShowSearchTips = ref(false)
    const isShowSearchTips2 = ref(false)
    const currentType = ref(0)
    const handlePhoneEnter = async (e, type) => {
      console.log(e.target.value)
      let keyword = e.target.value
      currentType.value = type
      // console.log(type);
      const res = await searchKeywordCustomer({
        keyword
      })
      // console.log(res);
      searchResList.value = res.data.list
      if (ruleForm.userMobile.length == 0 && type == 0) {
        searchResList.value = []
      }
      // console.log(searchResList.value);
      // console.log(searchResList.value.length);
      if (searchResList.value.length > 0) {
        if (type == 0) {
          // console.log(111);
          isShowSearchTips.value = true
        } else {
          // console.log(222);
          isShowSearchTips2.value = true
        }

      }
    }

    const handleDialogClick = () => {

    }
    const handlePageDialogClick = () => {
      isShowSearchTips.value = false
      isShowSearchTips2.value = false
    }

    const isShowUserInfo = ref(false)
    watch(clientInfo, (n) => {
      if (n.hasOwnProperty('userId')) {
        isShowUserInfo.value = true
        ruleForm.userId = n.userId
        ruleForm.arrivePerson = n.userRealname
        currentUser.userRealname = n.userRealname
        currentUser.userMobile = n.userMobile
        currentUser.userAva = n.userAva
      }
    })

    const currentUser = reactive({
      userRealname: '',
      userMobile: '',
      userAva: ''
    })

    const handleSearchUserClick = (item) => {
      isShowUserInfo.value = true
      ruleForm.userId = item.userId
      ruleForm.arrivePerson = item.userRealname
      currentUser.userRealname = item.userRealname
      currentUser.userMobile = item.userMobile
      currentUser.userAva = item.userAva
      ruleForm.userMobile = item.userMobile
      ruleForm.userRealname = item.userRealname
      phoneInputRef.value.focus()
      setTimeout(() => {
        phoneInputRef.value.blur()
      }, 200);
    }

    const handleOptProjectChange = item => {
      ruleForm.totalTime = item.totalTime
      ruleForm.reserveArr = item.reserveArr
      ruleForm.prepare = item.prepareTime
    }

    const phoneInputRef = ref()

    const handleSaveClick = async (formEl = undefined) => {
      if (!formEl) return
      await formEl.validate((valid, fields) => {
        if (valid) {
          // 判断是否选择项目
          if (ruleForm.reserveArr.length == 0) {
            ElMessage({
              message: '请选择服务项目!',
              type: 'warning',
            })
            return
          }
          loading.value=true
          let category = props.category

          let servers = []
          ruleForm.reserveArr.forEach(item=>{
            let arr = item.split(',')
            servers.push({
              serveId: arr[1],
              count: 1
            })
          })
          let params =  {
            "date": dayjs(ruleForm.arriveDate).format('YYYY-MM-DD'),
            "time": ruleForm.arriveTime+":00",
            "empId": ruleForm.empId,
            "itemList": servers,
            "userId": ruleForm.userId,
            "name": ruleForm.userRealname,
            "phone": ruleForm.userMobile,
            "remark":ruleForm.remark,
            "serveTimeLength":ruleForm.totalTime,
            "prepare":ruleForm.prepare,
            "shopId":ruleForm.shopId
          }
          // 普通预约
          if (category == 0) {
            // console.log('预约0！！！！！', ruleForm)

            newApp(params).then(res=>{
              const {code,msg} = res
              if(code === 0){
                emit('changeDialogVisible', false)
              }else{
                ElMessage.error(msg)
              }
            })
            // store.dispatch('reserve/addReserveAction', { ...ruleForm })

          } else if (category == 1) {
            emit('updateReserveProject', { ...ruleForm })
            changeDialogVisible(false)
          }
        } else {
          ElMessage({
            message: '带*为必填选择，请正确填写相关数据!',
            type: 'warning',
          })
          // console.log('error submit!', fields)
        }
        setTimeout(()=>{
          loading.value = false
        },1500)
      })
    }

    const teacherServeCategoryList = ref([{
      kindId: '',
      kindName: '所有分类'
    }])

    const initPage = async (shopId,empId) => {
      ruleForm.shopId = shopId
      ruleForm.empId = empId
      // await store.dispatch('reserve/getShopListAction', COMPANY_ID)
      // if(shopId!=0)  {
      //   ruleForm.shopId = shopId
      //   await store.dispatch('reserve/getShopTeacherListAction', shopId)
      // }
      // if (props.addInfo.teacherId) {
      //   const serveTypeRes = await getServeCategoryList(COMPANY_ID)
      //   let list = [...teacherServeCategoryList.value, ...serveTypeRes.data.list]
      //   teacherServeCategoryList.value = list
      // }
    }

    const disabledDate = (time) => {
      return time.getTime() < Date.now() - 8.64e7
    }


    const ruleFormRef = ref()

    onUnmounted(() => {
      emitter.off('changeAddReserveDialogShopId')
      emitter.off('changeAddReserveDialogTime')

    })

    const popoverVisible = ref(false)
    const popoverRef = ref()
    const buttonRef = ref()
    let currentOptIndex = ref(-1)
    const handleTimeClick = (time, index) => {
      currentOptIndex.value = index
      ruleForm.arriveTime = time
      // console.log(time);
      // console.log(index);
      popoverVisible.value = false
    }

    return {
      currentOptIndex,
      handleTimeClick,
      buttonRef,
      popoverVisible,
      popoverRef,
      currentType,
      isShowSearchTips2,
      teacherTimeList,
      handleDateChnage,
      handleCancleClick,
      currentOptTeacherName,
      handleOptTeacherClick,
      currentTeacherLvid,
      handleTeacherClick,
      popoverWidth,
      teacherRef,
      phoneInputRef,
      handleOptProjectChange,
      rules,
      ruleFormRef,
      handleServeTypeChange,
      teacherServeInfoList,
      optServeId,
      teacherServeCategoryList,
      RESERVE_START_TIME,
      getShopServeListFunc,
      shopServeList,
      timeStart,
      disabledDate,
      currentUser,
      handleSearchUserClick,
      handleDialogClick,
      handlePageDialogClick,
      isShowSearchTips,
      searchResList,
      searchKeywordCustomer,
      handlePhoneEnter,
      isShowUserInfo,
      handleClosrClick,
      handleSaveClick,
      clientInfo,
      handlePhonechange,
      handleCheckbox,
      handleCheckBoxChange,
      handleServeClick,
      currentOptServe,
      checkList,
      teacherServeList,
      handleTeacherChange,
      handleShopChange,
      shopList,
      ruleForm,
      changeDialogVisible,
      COMPANY_ID,
      shopTeacherList,
      emptyForm,
      initPage,
      totalTimeList,
      totalTimeList2,
      loading
    }

  }
})
</script>

<style scoped lang="less">
@media screen and (min-width: 1200px) {
}

&:deep(.el-scrollbar__view) {
  .reserve-time-project:after {
    content: '';
    flex: 1;
  }

  .reserve-time-project {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 20px 10px 10px;

    .reserve-item-time-disabled {
      color: #ccc !important;
      cursor: auto !important;
    }

    .optTimeActive {
      color: rgb(133, 88, 250);
      font-weight: 600 !important;
    }

    .reserve-item-time {
      margin: 5px 0;
      width: 56px;
      height: 30px;
      text-align: center;
      line-height: 100%;
      cursor: pointer;
    }
  }
}

.add-reserve-dialog {
  font-size: 12px;

  .arriveTime {
    line-height: 40px;
    height: 40px;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    display: flex;
    cursor: pointer;

    div {
      flex: 1;
      padding-left: 14px;
    }

    span {
      padding-right: 10px;
      display: inline-block;
      width: 34px;
      background: url('~@/assets/img/main/base/down.png') no-repeat center
        center;
    }
  }

  .reserve-project-list {
    position: relative;
    left: -100px;
  }

  &:deep(.el-dialog) {
    min-width: 800px;
    position: relative;
  }

  &:deep(.el-form-item__label) {
    word-spacing: normal;
  }

  .save-btn {
    display: flex;
    justify-content: flex-end;
    padding-right: 15px;
  }

  // &:deep(.el-scrollbar__view) {
  //   padding-right: 20px;
  // }

  &:deep(.is-horizontal) {
    display: none !important;
  }

  &:deep(.el-form-item__label) {
    font-size: 12px;
  }

  &:deep(.el-dialog) {
    font-size: 12px;
  }

  .search-user {
    position: relative;

    .search-tips2 {
      margin-top: 44px !important;
    }

    .search-tips {
      position: absolute;
      width: 300px;
      z-index: 100;
      background-color: #fff;
      // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
      margin-top: 6px;

      .user-item {
        display: flex;
        align-items: center;
        line-height: 46px;
        cursor: pointer;
        padding: 0 10px;
        border-bottom: 1px solid rgba(204, 204, 204, 1);

        img {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
    }

    .phones {
      display: flex;

      &:deep(.el-input) {
        min-width: 250px !important;

        .el-input__inner {
          min-width: 250px !important;
        }
      }
    }
    .phone {
      display: flex;

      &:deep(.el-input) {
        min-width: 300px !important;

        .el-input__inner {
          min-width: 300px !important;
        }
      }

      .user-info {
        width: 100%;
        display: flex;
        align-items: center;
        background-color: rgb(250, 232, 228);
        padding-left: 10px;
        position: relative;

        .close {
          position: absolute;
          right: 4px;
          top: 4px;
          font-size: 12px;
          cursor: pointer;
        }

        img {
          width: 30px;
          height: 30px;
          margin-right: 6px;
        }

        .name {
          margin-right: 6px;
        }
      }

      &:deep(.el-input) {
        max-width: 200px;
      }
    }
  }

  .to-shop {
    display: flex;

    .nickname {
      margin-left: 10px;
      flex: 1;
    }

    &:deep(.el-radio-group) {
      display: flex;
    }
  }

  .teacher {
    &:deep(.el-select):nth-child(1) {
      margin-right: 10px;
    }
  }

  .shop,
  .teacher {
    display: flex;
    justify-content: space-between;

    &:deep(.el-select) {
      width: 300px;

      .el-input {
        min-width: 300px;
      }

      .select-trigger {
        width: 100%;
      }
    }
  }

  .arrive-date {
    display: flex;
    justify-content: space-between;

    &:deep(.el-form-item) {
      flex: 1;

      .el-input,
      .el-select {
        width: 100%;
        min-width: 300px;
      }
    }

    &:deep(.el-form-item):nth-child(1) {
      margin-right: 5px;
    }

    &:deep(.el-form-item):nth-child(2) {
      margin-left: 5px;
    }

    .arriveDate {
      margin-right: 10px;
    }
  }

  .serve-content {
    .title {
      display: flex;
      margin-top: 10px;
      padding: 0 10px;
      background-color: rgb(239, 239, 239);

      div {
        flex: 1;
        line-height: 40px;
        // text-align: c;
      }
    }

    &:deep(.el-radio-group) {
      width: 100%;

      .el-radio {
        width: 100%;

        .el-radio__label {
          width: 100%;
        }
      }
    }

    .serve-list {
      // padding: 0 10px;

      .item {
        display: flex;

        &:deep(.el-checkbox) {
          display: inline-block;
          width: 100% !important;

          .el-checkbox__label {
            display: inline-block;
            width: 100%;
          }
        }

        .left {
          flex: 1;
        }

        .right {
          flex: 2;

          .checkbox {
            display: flex;

            div {
              flex: 1;
            }

            .salePrice {
              color: rgb(255, 105, 74);
            }
          }
        }
      }
    }

    &:deep(.el-checkbox-group) {
      .el-checkbox-button {
        width: 100%;
        margin-bottom: 10px;

        span {
          width: 100%;
        }
      }
    }
  }

  // &:deep(.el-select) {
  //   max-width: 160px;
  // }

  &:deep(.el-input) {
    margin-right: 10px;
  }
  &:deep(.el-input-number.is-without-controls .el-input__inner){
    text-align: left;
  }
}
</style>

<style>
.reserve-el-popover-wrap {
}

.reserve-project-list {
  position: relative;
}
</style>

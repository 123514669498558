<template>
  <div class="serve-item">
    <el-tree highlight-current node-key="paraId" ref="tree" :data="category == 1 ? dataList : teacherServeInfoList"
      :props="treeProps" show-checkbox @check-change="handleCheckChange">
      <template #default="{ node, data }">
        <div @click="handleClick(node, data)" class="custom-tree-node">
          <div>{{  data.serveName  }}</div>
          <div v-if="data.serveTimeLength">{{  data.serveTimeLength  }}分钟</div>
          <div v-if="data.salePrice">¥{{  (data.salePrice / 100).toFixed(2)  }}</div>
        </div>
      </template>
    </el-tree>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, watch } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  props: {
    dataList: {
      type: Array,
      default() {
        return []
      }
    },
    /*
    category
    0普通添加
    1 待分配
    */
    category: {
      type: Number,
      default: 0
    }
  },
  components: {

  },
  emits: ['optProjectChange'],
  setup(props, { emit }) {
    const store = useStore()

    const teacherServeList = computed(() => store.state.reserve.teacherServeList)
    const teacherServeInfoList = ref([])

    watch(teacherServeList, (n) => {
      if (n != null) {
        teacherServeInfoList.value = n
      }
    }, {
      deep: true,
      immediate: true
    })

    const treeProps = {
      label: 'serveName',
      children: 'paralist',
    }

    const tree = ref()
    const handleCheckChange = (data, checked, indeterminate) => {
      let list = tree.value.getCheckedNodes(true)
      let reserveArr = []
      let totalTime = 0
      list.forEach((item, index) => {
        reserveArr.push(`${item.projectName},${item.serveId},${item.paraId},${item.salePrice},${item.serveTimeLength}`)
        totalTime += item.serveTimeLength
      })
      emit('optProjectChange', {
        reserveArr,
        totalTime
      })
    }

    const handleClick = (node, data) => {
      // // console.log(node);
      // // console.log(data);
    }

    const initPage = () => {

    }


    return {
      tree,
      handleClick,
      handleCheckChange,
      teacherServeList,
      teacherServeInfoList,
      treeProps
    }

  },
})
</script>

<style scoped lang="less">
.serve-item {
  margin-top: 10px;

  &:deep(.custom-tree-node) {
    display: flex;
    width: 100%;

    &>div {
      flex: 1;
    }
  }
}
</style>
